@font-face {
    font-family: "XLight";
    /*Can be any text*/
    src: local("Gotham-Book"), url("../fonts/Gotham/Gotham-XLight.otf") format("truetype");
}

@font-face {
    font-family: "Light";
    /*Can be any text*/
    src: local("Gotham-Book"), url("../fonts/Gotham/Gotham-Light.otf") format("truetype");
}

@font-face {
    font-family: "Medium";
    /*Can be any text*/
    src: local("Gotham-Book"), url("../fonts/Gotham/Gotham-Medium.otf") format("truetype");
}

@font-face {
    font-family: "Bold";
    /*Can be any text*/
    src: local("Gotham-Book"), url("../fonts/Gotham/Gotham-Bold.otf") format("truetype");
}

@font-face {
    font-family: "Normal";
    /*Can be any text*/
    src: local("Gotham-Book"), url("../fonts/Gotham/Gotham-Book.otf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
}

body, html {
    transition: all 0.50s linear;
    font-family: "Medium";
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}